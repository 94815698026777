@import "../../functions";

.random {
  display: flex;
  height: 100%;

  &__image {

    &_first {
      height: 97%;
      margin-top: auto;
      margin-right: vw(239);
    }

    &_second {
      height: 40%;
      margin: auto vw(169) auto 0;
    }

    &_third {
      height: 93%;
      margin-top: vh(31);
      margin-right: vw(356);
      padding: vh(31) 0 vh(149) 0;
    }

    &-hands {
      position: absolute;
      height: 99%;
      margin-top: auto;
      left: -70%;
      bottom: 0;
      z-index: 1;
    }

    &_last {
      display: flex;
      align-items: center;
      height: 100%;

      &-image {
        height: 95%;
      }
    }
  }

  &__slider {
    position: relative;
    display: flex;
    align-items: center;
    height: 86%;
    margin: vh(104) vh(916) vh(52) 0;

    &-preview {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      row-gap: vh(50);
      height: 100%;
      margin-right: vh(297);

      .random__image {
        height: 29%;
      }
    }

    &_active {
      height: 85%;
    }
  }
}
