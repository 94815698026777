@import "../../functions";
@import "../../media";

.app-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: var(--navbar-width);
  height: 100vh;
  background-color: transparent;
  font-size: var(--navbar-font-size);
  line-height: 1;
  z-index: 5;

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: var(--navbar-gap);
    text-align: center;
    margin-top: vh(20);

    @media (hover:hover) {
      .item:hover {

        .item__overlay-text {
          opacity: 1;
          transform: translate(0, -50%);
        }

        .item__overlay {
          opacity: 0.93;
          animation: slide 0.7s;
        }
      }
    }

    .item {
      position: relative;
      text-align: end;

      &__link {
        position: relative;
        display: block;
        width: 100%;
        cursor: pointer;

        &_active {
          background-image: url("../../assets/navbar-eye.png");
          background-position: left 14px top;
          background-repeat: no-repeat;
          background-size: contain;

          @include mobileHorizontal {
            background-position: left 10px top;
          }
        }
      }

      &__overlay {
        position: absolute;
        top: 50%;
        bottom: 0;
        right: -20%;
        width: 100%;
        height: 100%;
        transform: translate(100%, -50%);
        white-space: nowrap;
        text-align: left;
        text-transform: uppercase;
        color: var(--black);
        opacity: 0;
        transition: .6s ease;
        pointer-events: none;
      }

      .item__link {
        display: flex;
        justify-content: flex-end;
        font-size: var(--navbar-font-size);
        line-height: 1;
        color: var(--black);
      }

      &-name {
        position: absolute;
        top: 50%;
        right: -100%;
        width: vw(162);
        transform: translate(vw(-162*2.5), -50%);
        white-space: nowrap;
        text-align: left;
        text-transform: uppercase;
        transition: .6s ease-in-out;
        pointer-events: none;
        z-index: 0;
      }
    }
  }
  
  @media (orientation: portrait) {
    display: none;
  }
  
  @media (max-height: 430px) {

    &__list {
      row-gap: 2px;
    }
  }
}

@keyframes slide {
  0% {
    transform:translate(80%, -50%);
  }
  100% {
    transform:translate(100%, -50%);
  }
}
