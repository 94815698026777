@import "reset";
@import "functions";
@import "media";

@import "./views/views";

@import "./components/app-sidebar/AppSidebar";
@import "./components/app-navbar/AppNavbar";
@import "./components/app-select/AppSelect";
@import "./components/app-preloader/AppPreloader";

@import "./sections/hero/Hero";
@import "./sections/gallery/Gallery";
@import "./sections/look-book/LookBook";
@import "./sections/line-sheet/LineSheet";
@import "./sections/random/Random";
@import "./sections/contacts/Contacts";

@include font-face("Helvetica NCB", "Helvetica Neue Condensed Black", 700);
@include font-face("Courier New bold", "CourierNewPS-BoldMT", 700);
@include font-face("Courier New", "CourierNew", 500);

:root {
  --white: #f5f5f5;
  --black: #0A0A0A;

  --navbar-width: 85px;
  --navbar-gap: 27px;
  --navbar-font-size: 50px;

  @include tabletHorizontal {
    --navbar-width: 80px;
    --navbar-font-size: 47px;
  }

  @include mobileHorizontal {
    --navbar-width: 51px;
    --navbar-gap: 17px;
    --navbar-font-size: 30px;
  }
}

html, body, #root, .swiper {
  height: 100%;
  overflow-y: hidden;
  overscroll-behavior: none;

  @include portrait {
    height: auto;
    overflow-y: auto;
    width: 100vw;
    overflow-x: hidden;
  }
}

html {
  background-color: var(--white);
}

// reusable styles
.no-newline {
  white-space: nowrap;
}

.about {
  width: 100%;
  height: 100%;
  padding: 1%;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    row-gap: vh(30);
    width: 66%;
    height: 100%;
    margin: auto auto 0 auto;

    @media (orientation: landscape) {
      padding-bottom: 3%;
    }

    /*
    правка для fullHD моника
    @include tabletHorizontal {
      width: 79%;
    }*/
    @media (max-height: 874px) {
      width: 79%;
    }

    @include mobileHorizontal {
      row-gap: vh(18, $iPhoneHeight);
      width: 75%;
      padding-bottom: 0;
    }

    @include portrait {
      width: 89%;
      margin-bottom: vh(120, $tabletHeight);
    }

    @include tabletVertical {
      row-gap: vw(21, $tabletWidth);
    }

    @include mobileVertical {
      row-gap: vw(15, $iPhoneWidth);
      width: 85%;
      margin-bottom: 0;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    row-gap: vh(30);
    text-align: center;

    @include mobileHorizontal {
      line-height: 0.95;
    }

    @include mobileVertical {
      text-align: justify;
    }

    &-comment {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      column-gap: 6px;
      font-style: italic;
      margin: vh(30) 0 vh(30) auto;

      @include mobileHorizontal {
        line-height: 1;
      }
    }
  }

  &__img {
    margin: 0 auto;
    height: 25%;

    @include tabletHorizontal {
      height: 20%;
    }

    @include mobileHorizontal {
      height: 16%;
    }

    @include portrait {
      width: 24%;
      margin: 0 14% 0 auto;

      img {
        max-height: initial;
        height: auto;
        max-width: 100%;
      }
    }
  }
}
