@import "../../functions";
@import "../../media";

.line-sheet {
  position: relative;
  height: 100%;
  width: 100%;
  padding: vh(39) 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  .form {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    row-gap: vh(32);
    height: 100%;
    max-width: 40%;
    max-height: 70%;
    z-index: 2;
    margin-right: 20%;

    @media (max-height: $tabletHeightPx) {
      max-width: 35%;
    }

    @media (max-height: $iPhoneHeightPx) {
      max-width: 25%;
    }

    &__title {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateY(-120%);
      line-height: 1;
      text-transform: uppercase;
      text-align: center;
    }

    &__item {
      position: relative;
      display: flex;
      align-items: center;
      max-height: 131px;
      width: fit-content;

      &-image {
        max-width: 100%;
        max-height: initial;
      }

      &-input {
        position: absolute;
        inset: 0 5% 0 7%;
        height: 100%;
        font-family: 'Courier New', arial;
        font-size: vh(20);
        color: var(--black);

        &::placeholder {
          color: var(--black);
        }
      }

      &_email {
        margin-left: 10px;
      }
    }

    &__submit {
      margin: 0 auto;
      min-height: 30px;
      max-height: 95px;
      height: 100%;

      @media (min-height: 621px) {
        height: 95px;
        width: 95px;
      }

      @media (max-height: 620px) {
        height: 70px;
        width: 70px;
      }

      @media (max-height: 520px) {
        height: 65px;
        width: 65px;
      }

      @media (max-height: 420px) {
        height: 50px;
        width: 50px;
      }

      @media (max-height: 320px) {
        height: 20px;
        width: 20px;
      }

      svg {
        transform: scale(0.65);
        max-height: 100%;
      }

      svg path {
        transition:  .3s ease-in;
      }

      &:hover svg path {
        fill: #009640;
      }
    }
  }

  &__trempel {
    position: absolute;
    top: -100%;
    left: 0;
    transform: translate(-50%, -50%) rotate(-30deg);
    height: 393%;
  }

  &__face {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    height: 100%;
    max-width: 41%;
    display: flex;
    justify-content: end;

    &-img {
      margin-top: auto;
      height: 86%;
      width: 100%;
      object-fit: contain;
      object-position: bottom;
    }
  }

  @media (orientation: portrait) {
    width: 100vw;
    padding: 0 6% 15% 6%;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;

    .form {
      max-height: initial;
      max-width: 100%;
      height: fit-content;
      margin: 0;

      &__title {
        top: -350%;
        text-align: left;
        left: 20%;
        transform: translateY(50%);
        width: fit-content;
        z-index: 1;
      }

      &__item-image {
        width: 100%;
        height: auto;
      }

      &__item_email {
        margin-left: 0;
      }
    }

    &__trempel {
      top: -200%;
      left: 15%;
      transform: translate(-50%, -50%) rotate(-21deg);
      height: initial;
      width: 71%;

      img {
        max-height: initial;
        height: auto;
        max-width: 100%;
      }
    }

    &__face {
      height: initial;
      max-height: initial;
      max-width: 37%;
      width: 100%;
      bottom: initial;
      top: 10%;
      right: -5%;
      transform: rotate(-47deg);

      @include mobileVertical {
        top: 0;
        right: -8%;
      }
    }
  }
}
