@function vw($value, $base: 1920) {
  @return $value / $base * 100vw;
}

@function vh($value, $base: 1080) {
  @return $value / $base * 100vh;
}

@mixin font-face($font-family, $url, $weight) {
  @font-face {
    font-family: '#{$font-family}';
    src: url('./fonts/#{$url}.woff') format('woff');
    font-weight: #{$weight};
    font-display: swap;
    font-style: normal;
  }
}
