@import "../../functions";
@import "../../media";

.contacts-wrap {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 9%;
}

.contacts {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: vw(15);
  width: 100%;
  max-width: 53%;
  max-height: 51%;
  margin: auto;
  height: 100%;
  text-align: center;

  &__title {
    position: absolute;
    left: 5%;
    top: 2%;
  }

  .contacts__link {
    text-decoration: underline;
    color: var(--black);
  }

  &__emails {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    margin-bottom: vh(50);
  }

  &__text {
    white-space: initial;
  }

  &__select {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    column-gap: 2px;

    .app-select {

      &__list :nth-child(3){
        white-space: initial;
      }
    }
  }

  &__images {
    align-self: center;
    display: flex;
    align-items: center;
    column-gap: vw(138);
    margin-right: 11%;
    max-height: 100%;

    &_first {
      height: 100%;
      max-height: 293px;
      min-height: 88px;
    }

    &_second {
      height: 100%;
      max-height: 307px;
      min-height: 92px;
    }

    img {
      max-height: inherit;
      height: 100%;
    }
  }

  @include portrait {
    max-width: initial;

    &__emails {
      padding-top: vw(72, $tabletHeight);
    }

    &__images {
      margin: 0;
      height: auto;
    }
  }

  @include tablet {

    &__images {

      &_first {
        max-height: 278px;
      }

      &_second {
        max-height: 291px;
      }
    }
  }

  @include mobile {

    &__images {

      &_first {
        max-height: 88px;
      }

      &_second {
        max-height: 92px;
      }
    }
  }

  @include tabletVertical {

    &__images {

      &_first {
        max-height: 208px;
      }

      &_second {
        max-height: 218px;
      }
    }
  }

  @include mobileVertical {

    &__emails {
      padding-top: vw(40, $iPhoneHeight);
    }

    &__images {

      &_first {
        max-height: 88px;
      }

      &_second {
        max-height: 92px;
      }
    }
  }
}
