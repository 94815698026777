@import "../../functions";
@import "../../media";

.look-book {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--white);

  &__title {
    position: absolute;
    top: 20%;
    left: 5%;
    transform: translateY(-50%);
    text-transform: uppercase;
  }

  .composition {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    height: 46vmin;
    background-image: url("../../assets/look-book/look-book-bg.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &__title {
      position: absolute;
      top: 0;
      right: 50%;
      transform: translate(21%, -130%);
      line-height: 1;
      text-transform: uppercase;
    }

    &__left-eye {
      position: absolute;
      top: 0;
      right: 50%;
      transform: translate(0%, 42%);
      height: 30%;
    }

    &__right-eye {
      position: absolute;
      bottom: 0;
      left: 50%;
      height: 59%;
      transform: translate(15%, -110%);
    }

    &__mouth {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-45%, 45%);
      height: 65%;

      img {
        height: 100%;
      }

      &-btn {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-transform: uppercase;
        color: #008BCA;
        font-size: vh(30);
        padding: vh(20) vh(45) vh(22) vh(45);
        border-radius: vh(25);
        transition: .3s ease;
        cursor: pointer;

        &:hover {
          color: var(--white);
        }
      }
    }

    &__text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -25%);
      text-align: center;
      mix-blend-mode: difference;
      letter-spacing: 0.05em;
      color: var(--white);
      z-index: 2;
      width: 54%;
    }
  }

  &__hand {
    position: absolute;
    max-width: 100%;
    max-height: initial;
    height: auto;

    &_left {
      left: 0;
      bottom: 0;
      width: 37vmax;
      transform: translate(-5%, 19%) rotate(-23deg);
    }

    &_right {
      right: 0;
      top: 0;
      width: 36vmax;
      transform: translate(6%, 6%) rotate(-15deg);
    }
  }

  @include tabletHorizontal {

    .composition {

      &__text {
        width: 60%;
      }
    }
  }

  @include mobileHorizontal {

    .composition {

      &__text {
        width: 80%;
      }
    }
  }

  @include portrait {

    .composition {

      &__title {
        transform: translateY(-100%);
      }

      &__text {
        width: 85%;
      }

      &__mouth-btn {
        font-size: vw(28, $tabletHeight);
      }
    }

    &__hand {

      &_left {
        width: 50vmax;
        transform: translateX(-41%) rotate(15deg);
      }

      &_right {
        width: 47vmax;
        transform: translateX(43%) rotate(-15deg);
      }
    }
  }

  @include mobileVertical {

    .composition{
      height: 69vmin;

      &__mouth {
        transform: translate(-45%, 54%);

        &-btn {
          font-size: vw(16, $iPhoneHeight);
        }
      }
    }

    &__hand {

      &_left {
        width: 28vmax;
        transform: translate(5%, -20%) rotate(15deg);
      }

      &_right {
        width: 27vmax;
        transform: translateY(20%) rotate(-15deg);
      }
    }
  }
}

