$mobileWidth: 430px;

$tabletWidthSmall: 768px;

$tabletWidthPx: 1366px;
$tabletHeightPx: 1024px;
$tabletWidth: 1366;
$tabletHeight: 1024;

$iPhoneWidth: 932;
$iPhoneHeight: 430;
$iPhoneHeightPx: 430px;
$iPhoneWidthPx: 932px;

@mixin portrait {
  @media (orientation: portrait) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $mobileWidth) or (max-height: $mobileWidth) {
    @content;
  }
}
@mixin mobileHorizontal {
  @media (max-height: $iPhoneHeightPx) and (orientation: landscape) {
    @content;
  }
}
@mixin mobileVertical {
  @media (max-width: $iPhoneHeightPx) and (orientation: portrait) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $tabletWidthPx) or (max-height: $tabletWidthPx) {
    @content;
  }
}
@mixin tabletHorizontal {
  @media (max-height: $tabletHeightPx) and (orientation: landscape) {
    @content;
  }
}
@mixin tabletVertical {
  @media (max-width: $tabletWidthPx) and (orientation: portrait) {
    @content;
  }
}

