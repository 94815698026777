@import "../../functions";
@import "../../media";

.gallery {
  display: flex;
  height: 100%;
  column-gap: vw(200);
  padding: vw(18) 0;

  &__image {
    height: 100%;

    &_first {
      height: 76%;
      margin-top: vh(32);

      @include tabletHorizontal {
        margin-left: vw(50, $tabletWidth);
      }
    }

    &_second {
      height: 86%;
      margin-top: vh(32);
    }

    &_third {
      height: 75%;
      margin-top: vh(32);
    }

    &_fourth {
      height: 100%;
      padding-top: vh(32);
    }

    &_fifth {
      height: 57%;
      margin-top: vh(170);
      aspect-ratio: 1;
    }

    &_sixth {
      height: 94%;
    }
  }
}
