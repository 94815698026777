@import "../media";
@import "../functions";

.auto-width {
  width: auto;
}

.auto-height {
  height: auto;
}

.screen-height {
  height: 100vh;
}

.text {
  font-size: vh(22);

  @include tabletHorizontal {
    font-size: vh(21, $tabletHeight);
  }

  @media (max-height: 1470px) and (orientation: landscape) {
    font-size: vh(21, $tabletHeight);
  }

  @include mobileHorizontal {
    font-size: vh(12, $iPhoneHeight);
  }

  @include portrait {
    font-size: vw(21, $tabletHeight);
  }

  @include tabletVertical {
    font-size: vw(21, $tabletHeight);
  }

  @include mobileVertical {
    font-size: vw(13, $iPhoneHeight);
  }
}

.title {
  text-transform: uppercase;
  font-size: vh(49, $tabletHeight);

  @include mobileVertical {
    font-size: vw(42, $iPhoneHeight);
  }
}

.portrait-screen {

  &__body {
    position: relative;
    z-index: 1;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    row-gap: vh(50);
  }

  &__lisa {
    display: flex;
    justify-content: center;
    padding: 10% 0;

    &-img {
      max-width: 79%;
      height: auto;
      max-height: initial;
    }
  }

  &__people {
    display: flex;
    align-items: center;
    width: 100vw;
    height: auto;
    padding: 0 5%;
    margin-bottom: vh(80, $tabletHeight);

    &-img {
      width: 100%;
    }
  }

  &__hands {
    position: relative;
    height: auto;
    padding-left: 38px;
    display: flex;
    background-color: var(--white);
    margin-bottom: vh(45, $tabletHeight);

    &_first {
      position: absolute;
      top: -40%;
      left: 5%;
      width: 100%;
      max-width: 43%;
      max-height: initial;
      z-index: 1;
    }

    &_second {
      margin-top: 30%;
      width: 100%;
      max-width: 83vw;
      max-height: initial;
      margin-left: auto;
    }
  }

  &__photo {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: auto;
    max-height: 1021px;
    margin-bottom: vh(75, $tabletHeight);

    &-img {
      width: 100%;
      max-width: 100%;
    }
  }

  &__lisa-photo {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    margin-bottom: vh(40, $tabletHeight);

    &-img {
      width: 62%;
    }
  }
}

.vertical-hero {
  position: relative;
  // отвечает за скорость прокрутки главного изображения
  // минмальное значение 100
  height: 250vh;

  &__inner {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
    z-index: 0;
  }

  &__moving-block {
    display: flex;
    height: 100vh;
  }

  &__background {
    height: 100%;

    &-picture {
      height: 100%;

    }

    &-logo {
      position: absolute;
      z-index: 1;
      top: 48%;
      right: 0;
      width: 73%;
    }
  }
}
