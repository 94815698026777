@import "../../functions";
@import "../../media";

.hero {
  $black-decorator-width: vw(197);

  position: relative;
  color: var(--white);
  text-transform: uppercase;
  width: 100%;
  height: 100%;

  &__inner {
    height: 100%;
    margin: 0 var(--navbar-width);
    padding: vh(33) 0;
  }

  &__background {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: fit-content;
    margin: 0 auto;

    &-picture {
      max-width: 100%;
      height: auto;
      display: block;
      margin: auto 0;
    }

    &-logo {
      position: absolute;
      z-index: 1;
      top: 48%;
      right: -13%;
      width: 86%;
    }
  }

  @include portrait {
    height: 100%;
    width: 200vw;

    &__inner {
      margin: 0;
      padding: 0;
    }

    &__background {

      &-picture {
        max-width: initial;
        height: 100%;
      }

      &-logo {
        width: 73%;
        right: 0;
      }
    }
  }
}
